import React, { useEffect, useLayoutEffect, useRef } from "react";
import axios from "axios";
import { NGROK } from "../../../APIs";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Typography } from "@mui/material";

import Timer from "..//../components/Timer";

import ".//../pages.scss";
import { CircularProgress, MenuItem, Select, Table } from "@mui/material";
import { useState } from "react";
import RadioAuthFilter from "../../components/RadioAuthFilter";
import useUserStore from "../../../services/userStore";
import ResourceRequestForm from "../../components/ResourceRequestForm";
import MyHeaderTableCell from "../Components/MyHeaderTableCell";
import {
  adminTrustLevelStyles,
  checkIfUserHasAccess,
  getFilteredEndpointsResources,
  getInheritedEndpointResourceStatusValue,
  userTrustLevelStyles,
} from "../../../services/Helpers";
import { myLocalStorage } from "../../../components/StorageHelper";
import useDebouncedValue from "../../../hooks/useDebouncedValue";
import { useLocation } from "react-router-dom";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";

const DataAccess = ({
  tenantName,
  showCount,
  setShowCount,
  selectedUser,
  setFoldersSearchValues,
  foldersSearchValues,
}) => {
  console.log(showCount);
  const tableRef = useRef();
  const location = useLocation();
  const locationState = location?.state;

  const activeComputer =
    locationState?.activeComputer || myLocalStorage.getItem("activeComputer");

  const userData = useUserStore((state) => state.user);
  const userRole = useUserStore((state) => state.user.role);

  const [folders, setFolders] = useState([]);
  const [loading, setLoading] = useState(false);

  const [filterFolderValue, setFilterFolderValue] = useState(
    userData.role !== "TENANT_USER" ? "ALL" : "DENIED",
  );

  const debouncedSearchTerm = useDebouncedValue(foldersSearchValues, 1000);

  const [timedFoldersWithoutAccess, setTimedFoldersWithoutAccess] = useState(
    [],
  );

  const changeFolderStatus = (newFolder) => {
    const updatedFolders = folders.map((folder) =>
      folder.folderId === newFolder.folderId ? newFolder : folder,
    );

    setFolders(updatedFolders);
  };

  const addFolderToUser = async (folder, groupStatus, inputTime) => {
    const timeInSeconds = (inputTime || activeComputer.defaultTime) * 60;

    try {
      const response = await axios.put(
        `${NGROK}/api/${tenantName}/computer-user/folders/timer`,
        {
          userId: folder.computerUserId,
          folderId: folder.folderId,
          email: userData.email,
          role: userData.role,
          groupStatus,
          selectedTime: timeInSeconds,
        },
      );
      if (!response.data) {
        changeFolderStatus(folder);
      } else changeFolderStatus(response.data);
    } catch (error) {
      changeFolderStatus(folder);
    }
  };

  const handleFilterResourceValue = (e) => {
    setFilterFolderValue(e.target.value);
  };

  const getUserFolders = () => {
    const timedFolders = getFilteredEndpointsResources(
      debouncedSearchTerm,
      "TIMED",
      folders,
    );

    const deniedFolders = getFilteredEndpointsResources(
      debouncedSearchTerm,
      "DENIED",
      folders,
    );

    return [...timedFolders, ...deniedFolders];
  };

  const getFilterdFolders = () => {
    const filteredFolders =
      userRole !== "TENANT_USER"
        ? getFilteredEndpointsResources(
            debouncedSearchTerm,
            filterFolderValue,
            folders,
          )
        : getUserFolders();
    return filteredFolders;
  };

  const checkIfTimeShouldBeShown = (folder) => {
    if (
      folder.groupStatus === "DYNAMIC" ||
      (folder.inheritedGroupStatus === "DYNAMIC" &&
        folder.groupStatus === "INHERITED")
    ) {
      return true;
    } else return false;
  };

  const findFolder = (value) => {
    setFoldersSearchValues(value);
  };

  useEffect(() => {
    const getFolders = async () => {
      setLoading(true);

      try {
        const response = await axios.get(
          `${NGROK}/api/${tenantName}/computers/${activeComputer?.id}/computer-users/${selectedUser.id}/folders`,
        );
        if (response.data) {
          setFolders(response.data.content);
        }
      } catch (error) {
        setFolders([]);
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    if (selectedUser) getFolders();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeComputer?.id, selectedUser, tenantName]);

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (tableRef.current) {
        const table = tableRef.current;
        const rows = table.querySelectorAll("tr");

        if (rows.length >= showCount) {
          const fiftiethRow = rows[showCount - 1];
          const rect = fiftiethRow.getBoundingClientRect();
          const tableRect = table.getBoundingClientRect();
          if (
            rect.top >= tableRect.top &&
            rect.bottom <= tableRect.bottom &&
            getFilterdFolders()?.length > showCount
          ) {
            setShowCount((prevCount) => prevCount + 50);
          }
        }
      }
    };

    const tableContainer = tableRef.current;
    if (tableContainer) {
      tableContainer.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (tableContainer) {
        tableContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [tableRef, folders, showCount, getFilterdFolders, setShowCount]);

  if (loading) {
    return (
      <Box display={"flex"} p={5}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box width={"fit-content"}>
      {folders?.length ? (
        <Stack spacing={2}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            sx={{ padding: "15px" }}
          >
            {userRole !== "TENANT_USER" ? (
              <RadioAuthFilter
                userRole={userRole}
                filterResourceValue={filterFolderValue}
                filterName={"Filter by privilege"}
                handleFilterResourceValue={handleFilterResourceValue}
              />
            ) : null}
            <TextField
              sx={{ width: 250 }}
              label="Search..."
              id="searchForGroup"
              value={foldersSearchValues}
              onChange={(e) => findFolder(e.target.value)}
            />
          </Box>
          <Typography
            variant="h6"
            color="initial"
            fontWeight={600}
            align="center"
          >
            Total Count: {getFilterdFolders()?.length || "0"}
          </Typography>

          <TableContainer
            component={Paper}
            sx={{
              height: "fit-content !important",
              maxHeight: "100vh !important",
              overflowY: "auto !important",
            }}
            ref={tableRef}
          >
            <Table
              sx={{
                width: "100%",
                height: "fit-content",
                "& td, & th": {
                  border: "1px solid #233044",
                },
              }}
            >
              <TableHead>
                <TableRow>
                  <MyHeaderTableCell align={"center"} className={"userName"}>
                    Folders
                  </MyHeaderTableCell>

                  <MyHeaderTableCell align={"center"} className={"userName"}>
                    Privilege level
                  </MyHeaderTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getFilterdFolders()
                  .slice(0, showCount)
                  .map((folder) => (
                    <TableRow className="groupRow" key={`${folder.folderId}`}>
                      <TableCell
                        component="td"
                        scope="row"
                        className="groupRowName userName"
                        title={folder.path}
                        sx={{
                          minWidth: "500px !important",
                          maxWidth: "500px !important",
                        }}
                      >
                        <Stack direction={"row"} spacing={1.5}>
                          <FolderOutlinedIcon />
                          <span>{folder.path}</span>
                        </Stack>
                      </TableCell>
                      <TableCell className="privilegeLevelTableCell">
                        <Box
                          className={
                            userData.role === "TENANT_USER"
                              ? userTrustLevelStyles(folder)
                              : adminTrustLevelStyles(folder)
                          }
                        >
                          <Select
                            disabled={userData.role === "TENANT_USER"}
                            sx={{ minWidth: "100px" }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={folder.groupStatus ? folder.groupStatus : ""}
                            size="small"
                            onChange={(event) =>
                              addFolderToUser(folder, event.target.value)
                            }
                          >
                            <MenuItem value={"ENABLED"}>ALLOWED</MenuItem>
                            <MenuItem value={"DISABLED"}>DENIED</MenuItem>
                            <MenuItem value={"DYNAMIC"}>TIMED</MenuItem>
                            <MenuItem value={"INHERITED"}>
                              INHERITED{" "}
                              {getInheritedEndpointResourceStatusValue(folder)}
                            </MenuItem>
                          </Select>
                          {checkIfTimeShouldBeShown(folder) ? (
                            <Timer
                              setTimedResourcesWithoutAccess={
                                setTimedFoldersWithoutAccess
                              }
                              resourceId={folder.folderId}
                              seconds={folder.remainingTime}
                            />
                          ) : null}

                          {!checkIfUserHasAccess(
                            folder,
                            userData,
                            timedFoldersWithoutAccess,
                            folder.folderId,
                          ) ? (
                            <ResourceRequestForm
                              tenantName={tenantName}
                              selectedUser={selectedUser}
                              resourceId={folder.folderId}
                              resourceType={"FOLDER"}
                              computer={activeComputer}
                              resourcePath={folder.path}
                              resourceName={folder.path}
                            />
                          ) : null}
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                {getFilterdFolders()?.length > showCount ? (
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell>
                      <TableCell colSpan={7} align="center">
                        <CircularProgress />
                      </TableCell>
                    </TableCell>
                  </TableRow>
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      ) : folders === undefined ? (
        <Box display={"flex"} p={5}>
          <CircularProgress />
        </Box>
      ) : (
        <Box pl={5}>
          <p style={{ fontSize: "20px", fontWeight: "500" }}>
            There are no any folders.
          </p>
        </Box>
      )}
    </Box>
  );
};

export default DataAccess;
