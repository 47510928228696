import React, { useState, useEffect } from "react";

import { Breadcrumbs, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link, useLocation } from "react-router-dom";
import useUserStore from "../services/userStore";
import { myLocalStorage } from "./StorageHelper";

const BreadcrumbsNavEndpoints = () => {
  const location = useLocation();

  const lastSession = useUserStore(state => state.lastSession);
  const activeComputer = useUserStore((state) => state.activeComputer);
  const latestComputer = activeComputer || myLocalStorage.getItem("activeComputer");
  const latestTenant = lastSession.latestTenant || myLocalStorage.getItem("latestTenant")?.tenantName;

  const [computerName, setComputerName] = useState("");

  useEffect(() => {
    if (latestComputer) {
      setComputerName(
        latestComputer.cn
          ? latestComputer.cn
          : latestComputer.dNSHostName
          ? latestComputer.dNSHostName
          : latestComputer.domainName
      );
    }
  }, [latestComputer]);

  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
      sx={{ fontSize: "22px" }}
    >
      <Link style={{ color: "black" }} to={"/endpoints"}>
        Tenants
      </Link>
      {latestTenant ? (
        <Typography fontSize={22} color="text.primary">
          {latestTenant}
        </Typography>
      ) : null}
      <Link style={{ color: "black" }} to={"/endpoints"}>
        Computers
      </Link>
      {location.pathname !== "/endpoints" && latestComputer ? (
        <Typography fontSize={22} color="text.primary">
          {computerName && computerName}
        </Typography>
      ) : null}
      {location.pathname === "/endpoints/resources" ? (
        <Typography fontSize={22} color="text.primary">
          Resources
        </Typography>
      ) : null}
      {location.pathname === "/endpoints/sessions" ? (
        <Typography fontSize={22} color="text.primary">
          Sessions
        </Typography>
      ) : null}
      {location.pathname === "/endpoints/connections" ? (
        <Typography fontSize={22} color="text.primary">
          Connections
        </Typography>
      ) : null}
      {location.pathname === "/endpoints/deviceAuditLogs" ? (
        <Typography fontSize={22} color="text.primary">
          Audit Logs
        </Typography>
      ) : null}
    </Breadcrumbs>
  );
};

export default BreadcrumbsNavEndpoints;
