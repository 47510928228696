import { TableCell, TableHead, TableRow } from "@mui/material";
import React from "react";

const EndpointsTableHead = ({ role }) => {
  return (
    <TableHead
      sx={{
        backgroundColor: "#233044",
      }}
    >
      <TableRow>
        <TableCell sx={{ color: "white", fontSize: "16px" }} align="center">
          Device
        </TableCell>
        <TableCell sx={{ color: "white", fontSize: "16px" }} align="center">
          Profile
        </TableCell>
        <TableCell sx={{ color: "white", fontSize: "16px" }} align="center">
          Operating Mode
        </TableCell>
        <TableCell sx={{ color: "white", fontSize: "16px" }} align="center">
          Resources
        </TableCell>
        <TableCell sx={{ color: "white", fontSize: "16px" }} align="center">
          Sessions
        </TableCell>
        <TableCell
          colSpan={1}
          sx={{ color: "white", fontSize: "16px" }}
          align="center"
        >
          Logs
        </TableCell>
        <TableCell sx={{ color: "white", fontSize: "16px" }} align="center">
          Connections
        </TableCell>
        <TableCell
          colSpan={1}
          sx={{ color: "white", fontSize: "16px" }}
          align="center"
        >
          Access
        </TableCell>
        {role !== "TENANT_USER" ? (
          <>
            <TableCell
              colSpan={1}
              sx={{ color: "white", fontSize: "16px" }}
              align="center"
            >
              Recording
            </TableCell>

            <TableCell
              colSpan={1}
              sx={{ color: "white", fontSize: "16px" }}
              align="center"
            >
              Management
            </TableCell>
          </>
        ) : null}
      </TableRow>
    </TableHead>
  );
};

export default EndpointsTableHead;
