import axios from "axios";
import { NGROK } from "../../../APIs";

export const fetchNotifications = async (setter) => {
  try {
    const response = await axios.get(`${NGROK}/api/access/admin-notifications`);
    setter(response.data);
  } catch (error) {
    console.error(error);
  }
};
