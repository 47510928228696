import React, { useEffect, useState } from "react";

import { TabContext, TabList } from "@mui/lab";
import { Tab, Typography, Box } from "@mui/material";
import ActiveDirectorySetup from "./Components/ActiveDirectorySetup";
import EmailNotification from "./Components/EmailNotification";
import { useLocation } from "react-router-dom";
import { myLocalStorage } from "../../../components/StorageHelper";
import TenantSelection from "../Components/TenantSelection";

const TenantSettings = () => {
  const locationState = useLocation();

  const tenant =
    locationState?.tenant || myLocalStorage.getItem("latestTenant");

  const [tabValue, setTabValue] = useState("ActiveDirectorySetup");
  const [selectedTenant, setSelectedTenant] = useState(tenant);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  useEffect(() => {}, [selectedTenant]);

  return (
    <>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={tabValue}>
          <Box
            sx={{ borderBottom: 1, borderColor: "divider" }}
            display={"flex"}
            justifyContent={"center"}
          >
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab
                label="Active Directory Setup"
                value={"ActiveDirectorySetup"}
              />
              <Tab label="Email Notification" value={"EmailNotification"} />
            </TabList>
          </Box>
          <CustomTabPanel value={tabValue} index={"ActiveDirectorySetup"}>
            <ActiveDirectorySetup />
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={"EmailNotification"}>
            <TenantSelection
              selectedTenant={selectedTenant}
              setSelectedTenant={setSelectedTenant}
            />
            <EmailNotification tenantName={selectedTenant?.tenantName} />
          </CustomTabPanel>
        </TabContext>
      </Box>
    </>
  );
};

const CustomTabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

export default TenantSettings;
