import React, { useEffect } from "react";
import axios from "axios";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import RequestMessageDialog from "../../components/RequestMessageDialog";
import moment from "moment";
import useNotificationStore from "../../../services/notificationStore";
import { NGROK } from "../../../APIs";
import useUserStore from "../../../services/userStore";

const ResourceRequestsUser = () => {
  const { userNotifications, setUserNotifications } = useNotificationStore();
  const userData = useUserStore((state) => state.user);

  const fetchNotifications = async () => {
    try {
      const response = await axios.get(
        `${NGROK}/api/access/user-notifications?userId=${userData.userId}`
      );
      setUserNotifications(response.data);
    } catch (error) {
      console.log(error, "error fetchNotifications");
    }
  };

  useEffect(() => {
    fetchNotifications();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          overflow: "hidden",
          display: "flex",
          height: "fit-content",
          minWidth: "800px",
        }}
      >
        <Table
          size="small"
          aria-label="a dense table"
          sx={{
            borderCollapse: "collapse",
            borderStyle: "hidden",
            height: "fit-content",
            "& td, & th": {
              border: "1px solid #233044",
            },
          }}
        >
          <TableHead
            sx={{
              backgroundColor: "#233044",
            }}
          >
            <TableRow>
              <TableCell
                sx={{ color: "white", fontSize: "16px" }}
                align="center"
              >
                Created At
              </TableCell>
              <TableCell
                sx={{ color: "white", fontSize: "16px" }}
                align="center"
              >
                Computer Name
              </TableCell>
              <TableCell
                sx={{ color: "white", fontSize: "16px" }}
                align="center"
              >
                Resource Category
              </TableCell>
              <TableCell
                sx={{ color: "white", fontSize: "16px" }}
                align="center"
              >
                Resource Name
              </TableCell>
              <TableCell
                sx={{ color: "white", fontSize: "16px" }}
                align="center"
              >
                Request Message
              </TableCell>
              <TableCell
                sx={{ color: "white", fontSize: "16px" }}
                align="center"
              >
                Privilege Level
              </TableCell>
              <TableCell
                align="center"
                sx={{ color: "white", fontSize: "16px" }}
              >
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userNotifications.length
              ? userNotifications.map((notification) => (
                  <TableRow key={notification.id}>
                    <TableCell sx={{ fontSize: "16px" }} align="center">
                      {moment(notification.createdAt).format(
                        "YYYY/MM/DD/HH:mm"
                      )}
                    </TableCell>
                    <TableCell sx={{ fontSize: "16px" }} align="center">
                      {notification.computerName}
                    </TableCell>
                    <TableCell sx={{ fontSize: "16px" }} align="center">
                      {notification.resourceType === "GROUP" &&
                      notification.resourceName === "Whiteswan Access"
                        ? "SESSION"
                        : notification.resourceType}
                    </TableCell>
                    <TableCell sx={{ fontSize: "16px" }} align="center">
                      {notification.resourceName}
                    </TableCell>
                    <TableCell sx={{ fontSize: "16px" }} align="center">
                      <RequestMessageDialog
                        messageText={notification.requestMessage}
                      />
                    </TableCell>
                    <TableCell sx={{ fontSize: "16px" }} align="center">
                      {notification.privilegeLevel === "PERMANENT" ? (
                        <Typography
                          fontWeight={500}
                          fontSize={16}
                          variant="body1"
                        >
                          PERMANENT
                        </Typography>
                      ) : (
                        <Stack spacing={2}>
                          <Typography
                            fontWeight={500}
                            fontSize={16}
                            variant="body1"
                          >
                            DYNAMIC
                          </Typography>
                          <Typography
                            fontWeight={500}
                            fontSize={16}
                            variant="body1"
                          >
                            {notification.selectedTime} min
                          </Typography>
                        </Stack>
                      )}
                    </TableCell>
                    <TableCell sx={{ fontSize: "16px" }}>
                      <Button
                        variant="outlined"
                        color={
                          notification.requestStatus === "APPROVED"
                            ? "success"
                            : "error"
                        }
                      >
                        {notification.requestStatus === "DECLINE"
                          ? "DECLINED"
                          : notification.requestStatus}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ResourceRequestsUser;
