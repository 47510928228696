import React, { useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Box,
} from "@mui/material";

import EditCategoryDialog from "./components/EditCategoryDialog";
import ConfirmDeleteDialog from "./components/ConfirmDeleteDialog";
import { toLowerCaseExceptFirst } from "../../../services/Helpers";

const CategoriesTable = ({
  resourceType,
  selectedCategory,
  setSelectedCategory,
  selectedTenantName,
  allSelectedResources,
  os,
  fetchResources,
  fetchCategories,
  setAllSelectedResources,
  categoryList,
  setCategoryList,
  removedAppDuplicates,
}) => {
  useEffect(() => {
    if (selectedTenantName && os) fetchCategories(os);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceType, os, selectedTenantName]);

  const sortedCategories = categoryList?.sort(
    (a, b) => b.reputationScore - a.reputationScore
  );

  const categoriesNames = sortedCategories.map((category) => category.name);

  return (
    <TableContainer
      sx={{
        height: "fit-content",
        marginTop: 2.5,
      }}
      component={Paper}
    >
      <Table
        sx={{
          height: "fit-content",
          "& td, & th": {
            border: "1px solid #233044",
          },
        }}
      >
        <TableHead sx={{ backgroundColor: "#233044" }}>
          <TableRow>
            <TableCell
              align="left"
              className="userName"
              sx={{ color: "white", width: 50 }}
            >
              <Typography fontSize={17} fontWeight={600}>
                Priority
              </Typography>
            </TableCell>
            <TableCell
              align="center"
              className="userName"
              sx={{ color: "white" }}
            >
              <Typography fontSize={17} fontWeight={600}>
                Categories
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedCategories?.length
            ? sortedCategories.map((category) => (
                <TableRow
                  className={
                    category.name === selectedCategory?.name
                      ? "userRow activeUserRow"
                      : "userRow"
                  }
                  key={category.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 1 } }}
                >
                  <TableCell align="center" className="userName">
                    <Typography fontSize={17}>
                      {category.reputationScore}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{ maxWidth: "100%", wordWrap: "break-word" }}
                    component="td"
                    className="userName"
                    onClick={() => {
                      setSelectedCategory(category);
                    }}
                  >
                    <Box display={"flex"} justifyContent={"space-between"}>
                      <Typography
                        className="userName"
                        fontSize={16}
                        maxWidth={"65%"}
                        sx={{ wordWrap: "break-word" }}
                      >
                        {toLowerCaseExceptFirst(category.name)}
                      </Typography>
                      <Box
                        width={150}
                        display={"flex"}
                        justifyContent={"space-between"}
                      >
                        <EditCategoryDialog
                          selectedCategory={selectedCategory}
                          setAllSelectedResources={setAllSelectedResources}
                          selectedTenantName={selectedTenantName}
                          fetchCategories={fetchCategories}
                          fetchResources={fetchResources}
                          os={os}
                          resourceType={resourceType}
                          setCategoryList={setCategoryList}
                          category={category}
                          allSelectedResources={allSelectedResources}
                          removedAppDuplicates={removedAppDuplicates}
                          categoriesNames={categoriesNames}
                        />
                        <ConfirmDeleteDialog
                          category={category}
                          setCategoryList={setCategoryList}
                          resourceType={resourceType}
                          os={os}
                          selectedTenantName={selectedTenantName}
                          selectedCategory={selectedCategory}
                          setSelectedCategory={setSelectedCategory}
                        />
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
              ))
            : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CategoriesTable;
