import React from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Box,
  ButtonGroup,
  Checkbox,
} from "@mui/material";

const MatchedResourceTable = ({
  matchedResources,
  resourceNameField,
  setManualExclusions,
  manualExclusions,
}) => {
  const headerName =
    resourceNameField === "dNSHostName" ? "Device" : "Resource";

  const handleAddExclusion = (resource) => {
    setManualExclusions((prev) => [
      ...prev,
      { id: resource.id, name: resource[resourceNameField] },
    ]);
  };

  const handleRemoveExclusion = (resource) => {
    const updatedExclusions = manualExclusions.filter(
      (exclusion) => exclusion.name !== resource[resourceNameField]
    );

    setManualExclusions(updatedExclusions);
  };

  const addAllToExclusions = () => {
    const newExclusionsList = matchedResources.map((resource) => ({
      id: resource.id,
      name: resource[resourceNameField],
    }));

    setManualExclusions(newExclusionsList);
  };

  const removeAllFromExclusions = () => {
    setManualExclusions([]);
  };

  const checkIfResourceInExclusions = (resource) => {
    return manualExclusions?.some((exclusion) => resource[resourceNameField] === exclusion.name);
  };

  const handleCheckBox = (resource) => {
    if (!checkIfResourceInExclusions(resource)) {
      handleAddExclusion(resource);
    } else handleRemoveExclusion(resource);
  };

  return (
    <Box>
      <ButtonGroup
        variant="outlined"
        aria-label="outlined primary button group"
        sx={{ display: "flex", justifyContent: "center", padding: 2 }}
      >
        <Button onClick={removeAllFromExclusions}>Include all</Button>
        <Button onClick={addAllToExclusions}>Remove all</Button>
      </ButtonGroup>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ minWidth: 40 }} align="left">
              Sl No
            </TableCell>
            <TableCell align="left">{headerName}</TableCell>
            <TableCell align="left">Included</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {matchedResources.map((resource, index) => (
            <TableRow
              key={`${resource.name}  ${index}`}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "& > *": {
                  color: checkIfResourceInExclusions(resource)
                    ? "text.disabled"
                    : "inherit",
                },
              }}
            >
              <TableCell component="td" scope="row" align="left">
                {index + 1}
              </TableCell>
              <TableCell
                sx={{
                  wordWrap: "break-word",
                  maxWidth: "260px",
                }}
                align="left"
              >
                {resource[resourceNameField]}
              </TableCell>
              <TableCell>
                <Checkbox
                  checked={!checkIfResourceInExclusions(resource)}
                  onChange={() => handleCheckBox(resource)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default MatchedResourceTable;
