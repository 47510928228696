import React, { useEffect, useState } from "react";
import axios from "axios";
import { NGROK } from "../../APIs";
import {
  Stack,
  Typography,
  Card,
  CardContent,
  Box,
  CardHeader,
  Link,
} from "@mui/material";
import CodeIcon from "@mui/icons-material/Code";
import DataObjectIcon from "@mui/icons-material/DataObject";
import { Home as HomeIcon, Info as InfoIcon } from "@mui/icons-material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import CallIcon from "@mui/icons-material/Call";
import { FaLinux, FaWindows, FaApple } from "react-icons/fa";

const About = () => {
  const [backendVersion, setBackendVersion] = useState("");
  const [error, setError] = useState("");
  const [windowsVersion, setWindowsVersion] = useState(null);
  const [linuxVersion, setLinuxVersion] = useState(null);
  const [macVersion, setMacVersion] = useState(null);

  const callForWindows = async () => {
    let url = `${NGROK}/api/agent/windows-agent-version`;
    const response = await axios.get(url);
    if (response?.data) {
      setWindowsVersion(response?.data);
    }
  };

  const callForLinux = async () => {
    let url = `${NGROK}/api/agent/linux-agent-version`;
    const response = await axios.get(url);
    if (response?.data) {
      setLinuxVersion(response?.data);
    }
  };

  const callForMac = async () => {
    let url = `${NGROK}/api/agent/mac-agent-version`;
    const response = await axios.get(url);
    if (response?.data) {
      setMacVersion(response?.data);
    }
  };

  const getVersion = async () => {
    try {
      const response = await axios.get(`${NGROK}/api/build-version`);
      setBackendVersion(response.data);
      setError("");
    } catch (error) {
      console.error(error.message);
      setError(error.message);
    }
  };

  useEffect(() => {
    getVersion();
    callForWindows();
    callForLinux();
    callForMac();
  }, []);

  if (error)
    return <div>Something went wrong during fetching version. {error}</div>;

  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          mb: 10,
        }}
      >
        <Box
          sx={{
            width: "50%",
            height: "auto",
            backgroundColor: "#233044",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <img
            src="https://mlgtjc9t3u5c.i.optimole.com/cb:YFtR.b7d9/w:1920/h:724/q:mauto/f:best/https://www.whiteswansecurity.com/wp-content/uploads/2023/09/Whiteswan-New-Logo-White.png"
            alt="Whiteswan Logo"
            style={{
              width: "50%",
              height: "auto",
            }}
          />
        </Box>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Stack spacing={5} direction={"row"}>
            <Card sx={{ width: 350 }}>
              <CardHeader title="Platform" />
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    fontSize={20}
                    variant="subtitle1"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Stack spacing={3}>
                      <Stack spacing={3} direction={"row"}>
                        <CodeIcon color="#233044" size={25} />
                        <Typography variant="h6">
                          Frontend Version:{" "}
                          <span
                            style={{
                              color: "#034358",
                            }}
                          >
                            5.2.40
                          </span>
                        </Typography>
                      </Stack>
                      <Stack spacing={3} direction={"row"}>
                        <DataObjectIcon color="#233044" size={25} />
                        <Typography variant="h6">
                          Backend Version:{" "}
                          <span
                            style={{
                              color: "#034358",
                            }}
                          >
                            {backendVersion}
                          </span>
                        </Typography>
                      </Stack>
                    </Stack>
                  </Typography>
                </Box>
              </CardContent>
            </Card>

            <Card sx={{ width: 350 }}>
              <CardHeader title="Agent" />
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    fontSize={20}
                    variant="subtitle1"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Stack spacing={3}>
                      <Stack spacing={3} direction={"row"}>
                        <FaWindows color="#233044" size={25} />
                        <Typography variant="h6">
                          Windows Version:{" "}
                          <span
                            style={{
                              color: "#034358",
                            }}
                          >
                            {windowsVersion}
                          </span>
                        </Typography>
                      </Stack>
                      <Stack spacing={3} direction={"row"}>
                        <FaLinux color="#233044" size={25} />
                        <Typography variant="h6">
                          Linux Version:{" "}
                          <span
                            style={{
                              color: "#034358",
                            }}
                          >
                            {linuxVersion}
                          </span>
                        </Typography>
                      </Stack>
                      <Stack spacing={3} direction={"row"}>
                        <FaApple color="#233044" size={25} />
                        <Typography variant="h6">
                          Mac Version:{" "}
                          <span
                            style={{
                              color: "#034358",
                            }}
                          >
                            {macVersion}
                          </span>
                        </Typography>
                      </Stack>
                    </Stack>
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Stack>
        </Box>
        <Stack
          spacing={10}
          direction={"row"}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: 20,
            padding: "16px 0",
            bottom: 0,
            width: "100%",
          }}
        >
          <Link
            href="https://www.linkedin.com/company/whiteswan-identity-security/"
            target="_blank"
            rel="noopener"
            style={{
              display: "flex",
              alignItems: "center",
              color: "black",
              textDecoration: "none",
            }}
          >
            <LinkedInIcon />
            <Typography variant="body1" sx={{ marginLeft: 1 }}>
              Linkedin
            </Typography>
          </Link>
          <Link
            href="https://www.whiteswansecurity.com/about/"
            target="_blank"
            rel="noopener"
            style={{
              display: "flex",
              alignItems: "center",
              color: "black",
              textDecoration: "none",
            }}
          >
            <InfoIcon />
            <Typography variant="body1" sx={{ marginLeft: 1 }}>
              About Us
            </Typography>
          </Link>
          <Link
            href="https://www.whiteswansecurity.com/contact/"
            target="_blank"
            rel="noopener"
            style={{
              display: "flex",
              alignItems: "center",
              color: "black",
              textDecoration: "none",
            }}
          >
            <CallIcon />
            <Typography variant="body1" sx={{ marginLeft: 1 }}>
              Contact Us
            </Typography>
          </Link>

          <Link
            href="https://www.whiteswansecurity.com/"
            target="_blank"
            rel="noopener"
            style={{
              display: "flex",
              alignItems: "center",
              color: "black",
              textDecoration: "none",
            }}
          >
            <HomeIcon />
            <Typography variant="body1" sx={{ marginLeft: 1 }}>
              WhiteSwan
            </Typography>
          </Link>
        </Stack>

        <Box
          sx={{
            padding: 2,
            width: "85%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: "auto",
            bottom: 0,
            position: "fixed",
          }}
        >
          <Typography variant="h6">
            © {new Date().getFullYear()} Whiteswan Security Inc. All rights
            reserved.
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default About;
