import { AppBar, Box, Toolbar } from "@mui/material";
import React from "react";
import { bgBlur } from "./HFConfig";
import { MainLogo } from "../../../../components/MainLogo";
import Footer from "./Footer";

const AuthenticationPage = (props) => {
  const { theme, children } = props;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        background: "#f7f7f7 !important",
      }}
    >
      <AppBar
        sx={{
          boxShadow: "0px 0.1px 0.1px rgba(0, 0, 0, 0.1)",
          height: 90,
          zIndex: theme.zIndex.appBar + 1,
          ...bgBlur({ color: theme.palette.background.default }),
          transition: theme.transitions.create(["height"], {
            duration: theme.transitions.duration.shorter,
          }),
        }}
      >
        <Toolbar
          sx={{
            height: 1,
            px: { lg: 5 },
          }}
        >
          <img
            src="https://www.whiteswansecurity.com/wp-content/uploads/2023/09/Whiteswan-New-Logo-Blue.png"
            style={{
              width: 220,
              height: 85,
              verticalAlign: "middle",
              display: "inline",
              padding: 5,
            }}
          />
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {children}
      </Box>
      <Footer theme={theme} />
    </Box>
  );
};

export default AuthenticationPage;
