import React, { useState } from "react";

import RequestsTable from "./Components/RequestsTable";
import { Button, Stack } from "@mui/material";
import useGeneralSearch from "../../../hooks/useGeneralSearch";
import useNotificationStore from "../../../services/notificationStore";
import SearchField from "../logs/components/SearchField";
import CustomDatePicker from "../logs/components/CustomDatePicker";
import useDateFilter from "../../../hooks/useDateFilter";
import { handleGeneratePDF } from "./helpers";
import TenantSelection from "../Components/TenantSelection";
import { myLocalStorage } from "../../../components/StorageHelper";

const ResourceRequests = () => {
  const tenant = myLocalStorage.getItem("latestTenant");

  const { adminNotifications, setAdminNotifications } = useNotificationStore();
  const [selectedTenant, setSelectedTenant] = useState(tenant);

  const allowedSearchFields = [
    "createdAt",
    "email",
    "computerName",
    "resourcePath",
    "requestStatus",
    "privilegeLevel",
  ];
  const {
    searchTerm,
    filteredData: notificationsFilteredBySearch,
    handleSearch,
  } = useGeneralSearch(adminNotifications, allowedSearchFields);

  const {
    dateRange,
    handleChangeDateRange,
    filteredData: filteredNotifications,
  } = useDateFilter(notificationsFilteredBySearch, "createdAt");

  const tenantNotifications =
    filteredNotifications?.filter(
      (notification) => notification.tenantName === selectedTenant?.tenantName,
    ) || [];

  return (
    <Stack spacing={2}>
      <TenantSelection
        selectedTenant={selectedTenant}
        setSelectedTenant={setSelectedTenant}
      />
      <Stack direction={"row"} spacing={2}>
        <CustomDatePicker
          dateRange={dateRange}
          handleChangeDateRange={handleChangeDateRange}
        />
        <SearchField searchTerm={searchTerm} handleSearch={handleSearch} />
        <Button
          variant="outlined"
          onClick={() =>
            handleGeneratePDF(dateRange, tenantNotifications, searchTerm)
          }
          disabled={tenantNotifications.length === 0}
        >
          Download Report
        </Button>
      </Stack>

      <RequestsTable
        adminNotifications={tenantNotifications}
        setAdminNotifications={setAdminNotifications}
      />
    </Stack>
  );
};

export default ResourceRequests;
