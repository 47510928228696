import React from "react";
import {
  Button,
  Checkbox,
  FormControl,
  MenuItem,
  Select,
  Stack,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import Devicelist from "./Devicelist";
import ResourceRequestForm from "../../../components/ResourceRequestForm";
import { myLocalStorage } from "../../../../components/StorageHelper";
import useUserStore from "../../../../services/userStore";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import ConfigureConnectionModal from "./ConfigureConnectionModal";

const EndpointsTableBody = ({
  getComputers,
  deviceList,
  updateDeviceProfile,
  updateDeviceOperationMode,
  handleConnectButton,
  toggleRecording,
  profiles,
  fetchDataProfiles,
  setProfiles,
  selectedTenant,
  setOpenDeleteDeviceAlert,
  openDeleteDeviceAlert,
  upgradeMachine,
}) => {
  const navigate = useNavigate();
  const setActiveComputer = useUserStore((state) => state.setActiveComputer);
  const { role, userId } = useUserStore((state) => state.user);
  const setComputerId = useUserStore((state) => state.setComputerId);

  const isDeviceWindows = (device) => {
    if (device.OperatingSystem.toLowerCase().includes("windows")) return true;
    else return false;
  };

  const connectButtonEnabled = (device) => {
    const isEnabled = device.connectionCreatedFor?.some(
      (user) => userId === user.userId && role === user.role,
    );

    return isEnabled;
  };

  return (
    <TableBody>
      {deviceList?.map((device) => (
        <TableRow
          key={device.id}
          sx={{
            border: 1,
          }}
          onClick={() => {
            setActiveComputer(device);
            myLocalStorage.setItem("activeComputer", device);
          }}
        >
          <TableCell sx={{ fontSize: "16px", padding: 0 }}>
            <Devicelist device={device} upgradeMachine={upgradeMachine} />
          </TableCell>
          <TableCell sx={{ fontSize: "16px" }} align="center">
            <FormControl>
              <Select
                disabled={role === "TENANT_USER"}
                sx={{ minWidth: "150px" }}
                onOpen={() => fetchDataProfiles(device.id)}
                onClose={() => setProfiles([])}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={device.profile || " "}
                size="small"
                onChange={(event) => updateDeviceProfile(event, device)}
              >
                {profiles?.map(
                  (profile) =>
                    profile.name !== device.profile && (
                      <MenuItem value={profile.name} key={profile.id}>
                        {profile.name || "Select profile"}
                      </MenuItem>
                    ),
                )}
                <MenuItem value={device.profile}>
                  {device.profileIsOverwrittenByCategory
                    ? `INHERITED (${device.profile})`
                    : device.profile}
                </MenuItem>
                {!profiles && <MenuItem value="">Loading...</MenuItem>}
              </Select>
            </FormControl>
          </TableCell>
          <TableCell sx={{ fontSize: "16px" }} align="center">
            <Select
              disabled={role === "TENANT_USER"}
              sx={{ minWidth: "100px" }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={device.mode || " "}
              size="small"
              onChange={(event) => updateDeviceOperationMode(event, device)}
            >
              <MenuItem value={"AUDITING"}>AUDITING</MenuItem>
              <MenuItem value={"ENFORCING"}>ENFORCING</MenuItem>
              <MenuItem value={"DISABLED"}>DISABLED</MenuItem>
              <MenuItem value={"INHERITED_FROM_PROFILE"}>
                INHERITED{" "}
                {`(${
                  device.profileMode !== "INHERITED_FROM_TENANT"
                    ? device.profileMode
                    : selectedTenant.mode
                })`}
              </MenuItem>
            </Select>
          </TableCell>
          <TableCell
            onClick={() => {
              navigate("resources", {
                state: { selectedTenant, activeComputer: device },
              });
            }}
            sx={{ fontSize: "16px" }}
            align="center"
          >
            <Button
              variant="text"
              onClick={() => {
                setComputerId(device.id);
              }}
            >
              Resources
            </Button>
          </TableCell>
          <TableCell
            onClick={() => {
              if (role !== "TENANT_USER")
                navigate("sessions", {
                  state: device,
                });
            }}
            sx={{ fontSize: "16px" }}
            align="center"
          >
            <Button
              disabled={role === "TENANT_USER"}
              variant="text"
              onClick={() => {
                setComputerId(device.id);
              }}
            >
              Sessions
            </Button>
          </TableCell>
          <TableCell sx={{ fontSize: "16px" }} align="center">
            <Button
              disabled={role === "TENANT_USER"}
              size="small"
              variant="text"
              onClick={() => {
                navigate("deviceAuditLogs", {
                  state: {
                    selectedTenant,
                    activeComputer: device,
                  },
                });
              }}
            >
              Audit Logs
            </Button>
          </TableCell>
          <TableCell
            onClick={() => {
              if (role !== "TENANT_USER")
                navigate("connections", {
                  state: device,
                });
            }}
            sx={{ fontSize: "16px" }}
            align="center"
          >
            <Button
              disabled={role === "TENANT_USER"}
              variant="text"
              onClick={() => {
                setActiveComputer(device);
                myLocalStorage.setItem("activeComputer", device);
              }}
            >
              Connections
            </Button>
          </TableCell>
          <TableCell sx={{ fontSize: "16px" }} align="center">
            {role !== "TENANT_USER" ||
            (role === "TENANT_USER" && device.hasAccess) ? (
              <Stack
                spacing={2}
                direction={"row"}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Button
                  disabled={
                    !isDeviceWindows(device) && !connectButtonEnabled(device)
                  }
                  onClick={() => {
                    handleConnectButton(device);
                  }}
                >
                  Connect
                </Button>
                {!isDeviceWindows(device) ? (
                  <Box sx={{ cursor: "pointer" }}>
                    <ConfigureConnectionModal
                      getComputers={getComputers}
                      device={device}
                      tenantName={selectedTenant?.tenantName}
                    />
                  </Box>
                ) : null}
              </Stack>
            ) : (
              <ResourceRequestForm
                tenantName={selectedTenant.tenantName}
                resourceName={"Whiteswan Access"}
                resourcePath={"Whiteswan Accesss"}
                sessionRequest={true}
                resourceType={"GROUP"}
                computer={device}
                resourceId={device.whiteswanAccessGroupId}
                isWhiteswanAccess={device.isWhiteswanAccess}
              />
            )}
          </TableCell>

          {role !== "TENANT_USER" ? (
            <>
              <TableCell sx={{ fontSize: "16px" }} align="center">
                <Checkbox
                  checked={device.recordingPathEnabled}
                  onChange={() => toggleRecording(device)}
                />
              </TableCell>

              <TableCell sx={{ fontSize: "16px" }} align="center">
                <Button
                  onClick={() =>
                    setOpenDeleteDeviceAlert(!openDeleteDeviceAlert)
                  }
                  size="small"
                  variant="outlined"
                  color="error"
                >
                  Delete
                </Button>
              </TableCell>
            </>
          ) : null}
        </TableRow>
      ))}
    </TableBody>
  );
};

export default EndpointsTableBody;
