import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React from "react";

const SelectOS = ({ selectedOS, setSelectedOS }) => {
  const osList = ["Windows", "Linux",  "MacOS"];

  return (
    <>
      <FormControl>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          value={selectedOS}
          onChange={(e) => setSelectedOS(e.target.value)}
          name="radio-buttons-group"
          row
        >
          {osList.map((os) => (
            <FormControlLabel key={os} value={os} control={<Radio />} label={os} />
          ))}
        </RadioGroup>
      </FormControl>
    </>
  );
};

export default SelectOS;
